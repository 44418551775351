import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLogged = false;
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() hideSidenav = new EventEmitter<void>();
  public customerName = environment.CustomerTitle;

  constructor(private authSvc: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authSvc.isLogged$.subscribe((logged) => {
      this.isLogged = logged;

      if (!logged) this.hideSidenav.emit();
      if (logged) this.onToggleSidenav();
    });
  }

  onToggleSidenav(): void {
    this.toggleSidenav.emit();
  }

  onLogout() {
    this.authSvc.logout().subscribe();
    this.router.navigate(['login']);
  }
}
