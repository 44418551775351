<mat-toolbar color="primary" class="example-toolbar">
  <ng-container *ngIf="!isLogged; else viewAdmin">
    <span class="app-name">{{customerName}}</span>
    <span class="spacer"></span>
    <div>
      <a mat-button [routerLink]="'/login'">Iniciar Sesión</a>
    </div>
  </ng-container>
</mat-toolbar>
<ng-template #viewAdmin>
  <button mat-icon-button (click)="onToggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="app-name">{{customerName}}</span>
  <span class="spacer"></span>
  <div>
    <a mat-button (click)="onLogout()">Cerrar Sesión</a>
  </div>
</ng-template>